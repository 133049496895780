import React from 'react';

/* Import Select from react-select library */
import Select from 'react-select';

function SelectOptions({ label, options, selectedValue, onChange, placeholder }) {

  const selectOptions = options.map(option => ({
    value: option.slug,
    label: option.name,
  }));

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: '0.90rem',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '0.90rem',
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '0.90rem',
    }),
  };

  const selectedOption = selectOptions.find(option => option.value === selectedValue);

  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <Select
        value={selectedOption || null}
        onChange={option => onChange(option ? option.value : null)}
        options={selectOptions}
        placeholder={placeholder}
        styles={selectStyles}
        isClearable
      />
    </div>
  );
}

export default SelectOptions;
