import React, { useState, useEffect } from 'react';
import { auth, provider } from '../apiCalls/firebaseConfig';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { FaGoogle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const successMessage = location.state?.successMessage || '';

  useEffect(() => {
    if (successMessage) {
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [successMessage, navigate, location.pathname]);

  const handleGoogleSignIn = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then((result) => {
        if (result.user.emailVerified) {
          setLoading(false);
          navigate('/profile');
        } else {
          setError('Please verify your email address before logging in.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to sign in with Google');
        setLoading(false);
      });
  };

  const handleEmailSignIn = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        if (result.user.emailVerified) {
          setLoading(false);
          navigate('/profile');
        } else {
          setError('Please verify your email address before logging in.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to sign in with email and password');
        setLoading(false);
      });
  };

  return (
    <Container className="py-5">
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <div className="w-100" style={{ maxWidth: '400px' }}>
          <h1 className="text-center mb-4">Σύνδεση</h1>
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleEmailSignIn}>
            <Form.Group id="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group id="password" className="mb-3">
              <Form.Label>Κωδικός πρόσβασης</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Είσοδος
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">or</div>
          <Button
            className="w-100 mt-3"
            onClick={handleGoogleSignIn}
            disabled={loading}
            variant="outline-primary"
          >
            <FaGoogle /> Sign in with Google
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default LoginPage;
