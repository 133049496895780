// src/pages/SignUpPage.js

import React, { useState } from 'react';
import { auth, provider } from '../apiCalls/firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup } from 'firebase/auth';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function SignUpPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle Google Sign-Up
  const handleGoogleSignUp = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result);
        setLoading(false);
        navigate('/login', { state: { successMessage: 'Registration successful! Please log in.' } });
      })
      .catch((error) => {
        console.error(error);
        setError('Failed to sign up with Google');
        setLoading(false);
      });
  };

  // Handle Email/Password Sign-Up
  const handleEmailSignUp = (e) => {
    e.preventDefault();
    setError(''); // Clear any existing errors

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        console.log(result);

        // Send verification email
        sendEmailVerification(result.user)
          .then(() => {
            console.log('Verification email sent.');
            setLoading(false);
            navigate('/login', { state: { successMessage: 'Registration successful! Please check your email to verify your account and then log in.' } });
          })
          .catch((error) => {
            console.error('Error sending verification email:', error);
            setError('Failed to send verification email.');
            setLoading(false);
          });

      })
      .catch((error) => {
        console.error(error);
        setError(error.message); // Display the actual error message
        setLoading(false);
      });
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <div className="w-100" style={{ maxWidth: '400px' }}>
        <h1 className="text-center mb-4">Εγγραφή</h1>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleEmailSignUp}>
          <Form.Group id="email" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group id="password" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group id="confirm-password" className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button disabled={loading} className="w-100" type="submit">
            Sign Up
          </Button>
        </Form>
        <div className="w-100 text-center mt-3">or</div>
        <Button
          className="w-100 mt-3"
          onClick={handleGoogleSignUp}
          disabled={loading}
          variant="outline-primary"
        >
          <FaGoogle /> Sign up with Google
        </Button>
      </div>
    </Container>
  );
}

export default SignUpPage;
