import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

/* Import fetchGraphQLData to facilitate the apiCalls to the Hygraph API */
import fetchGraphQLData from "../apiCalls/fetchGraphQLData";

/* Import UI components from Bootstrap UI */
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import { Helmet } from 'react-helmet';
import './LawBlogPost.css';

function BlogPostPage() {
  const { blogSlug } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    const fetchBlogPostDetails = async () => {
      const query = `
      {
        lawBlogPost(where: { lawPostSlug: "${blogSlug}" }) {
          lawPostTitle
          lawPostDate
          lawPostBody {
            markdown
          }
          lawyer {
            firstName
            lastName
          }
        }
      }
      `;

      const data = await fetchGraphQLData(query);
      
      if (data && data.lawBlogPost) {
        setBlogPost(data.lawBlogPost);
      }
    };

    fetchBlogPostDetails();
  }, [blogSlug]);

  if (!blogPost) {
    return (
      <Container className="my-4">
        <h2>Loading...</h2>
      </Container>
    );
  }

  return (
    <Container className="mb-4">
      <Helmet>
        <title>{blogPost.lawPostTitle} - upLawyer.gr - Βρές δικηγόρο και άλλες νομικές υπηρεσίες στην Ελλάδα.</title>
        <meta
          name="description"
          content={`Διαβάστε το άρθρο με τίτλο "${blogPost.lawPostTitle}" από ${blogPost.lawyer.firstName} ${blogPost.lawyer.lastName} στο upLawyer.gr.`}
        />
      </Helmet>
      <Card className="blog-post">
        <Card.Body>
          <Card.Title className="blog-post-title my-4">
            {blogPost.lawPostTitle}
          </Card.Title>
          <Card.Subtitle className="blog-post-meta">
            <div>Δημοσίευση: {blogPost.lawPostDate}</div>
            {blogPost.lawPostDate && blogPost.lawyer && blogPost.lawyer.firstName && blogPost.lawyer.lastName && (
              <span className="separator">{' | '}</span>
            )}
            <div>Αρθρογράφος: {blogPost.lawyer.firstName} {blogPost.lawyer.lastName}</div>
          </Card.Subtitle>
          <ReactMarkdown className="blog-post-body my-4">{blogPost.lawPostBody.markdown}</ReactMarkdown>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default BlogPostPage;
