import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetchGraphQLData from '../apiCalls/fetchGraphQLData';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LawyerCard from '../components/LawyerCard';
import { Helmet } from 'react-helmet';

function AssociationLawyersPage() {
  const { associationSlug } = useParams();
  const [lawyers, setLawyers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLawyersByAssociation = async () => {
      const query = `
      {
        lawyers(where: { lawAssociation: { associationSlug: "${associationSlug}" } }) {
          id
          firstName
          lastName
          slugName
          lawSpecialty {
            specialtyName
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
        }
      }
      `;
      const data = await fetchGraphQLData(query);
      if (data && data.lawyers) {
        setLawyers(data.lawyers);
      }
      setLoading(false);
    };

    fetchLawyersByAssociation();
  }, [associationSlug]);

  if (loading) {
    return (
      <Container className="my-4">
        <h2>Loading...</h2>
      </Container>
    );
  }

  if (lawyers.length === 0) {
    return (
      <Container className="my-4">
        <Helmet>
          <title>Δεν βρέθηκαν δικηγόροι για αυτόν τον {lawyers[0].lawAssociation.associationName} - upLawyer.gr - Βρές δικηγόρο και άλλες νομικές υπηρεσίες στην Ελλάδα.</title>
          <meta
            name="description"
            content="Δεν υπάρχουν δικηγόροι διαθέσιμοι για τον επιλεγμένο σύλλογο."
          />
        </Helmet>
        <h2>Δεν βρέθηκαν δικηγόροι για αυτόν τον σύλλογο.</h2>
      </Container>
    );
  }

  return (
    <Container className="my-4">
      <Helmet>
        <title>{lawyers[0].lawAssociation.associationName} - upLawyer.gr - Βρές δικηγόρο και άλλες νομικές υπηρεσίες στην Ελλάδα.</title>
        <meta
          name="description"
          content={`Πλήρης λίστα δικηγόρων του συλλόγου ${lawyers[0].lawAssociation.associationName}. Βρείτε εξειδικευμένους δικηγόρους για τις νομικές σας ανάγκες.`}
        />
      </Helmet>
      <h2 className="h2-page-title text-center mt-4 mb-2">
        {lawyers[0].lawAssociation.associationName}
      </h2>
      <h5 className="h5-page-subtitle text-center mt-3 mb-5">
        Η πλήρης λίστα των διαθέσιμων δικηγόρων του συλλόγου
      </h5>
      <Row>
        {lawyers.map((lawyer) => (
          <Col key={lawyer.id} sm={12} md={6} lg={4} className="mb-4">
            <LawyerCard lawyer={lawyer} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default AssociationLawyersPage;
