// src/components/PublicRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Import your AuthContext

function PublicRoute({ children }) {
  const { currentUser } = useAuth(); // Get the current user from context

  // Check if the user is unauthenticated
  return !currentUser ? children : <Navigate to="/profile" />;
}

export default PublicRoute;
