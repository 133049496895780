import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

/* Import UI components from react-bootstrap library */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import fetchGraphQLData from "../apiCalls/fetchGraphQLData";

import LawyerCard from "../components/LawyerCard";

/* Import UI generic components  */
import LoadingSpinner from "../components/ui-spinner";

/* Import UI components from lawyer-components  */
import LawyerBiography from "../components-lawyer/LawyerBio";
import LawyerEducation from "../components-lawyer/LawyerEdu";
import LawyerLanguages from "../components-lawyer/LawyerLang";
import LawyerBlogPosts from "../components-lawyer/LawyerBlog";

/* Import react-helmet library for SEO optimization  */
import { Helmet } from "react-helmet";

import "./LawyerDetail.css";

const languageToCountryCode = {
  english: "GB",
  french: "FR",
  german: "DE",
  spanish: "ES",
};

function LawyerDetailPage() {
  const { slugName } = useParams();
  const [lawyer, setLawyer] = useState(null);

  useEffect(() => {
    const fetchLawyerDetails = async () => {
      const query = `
      {
        lawyers(where: { slugName: "${slugName}" }) {
          id
          firstName
          lastName
          lawSpecialty {
            specialtyName
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
          lawBlogPost {
            lawPostTitle
            lawPostBody {
              markdown
            }
            lawPostSlug
          }
          lawSchoolUnder {
            lawSchoolName
          }
          lawSchoolPost {
            lawSchoolName
            lawSchoolTitle
          }
          lawLanguage
          lawShortBio
        }
      }
      `;

      const data = await fetchGraphQLData(query);

      if (data && data.lawyers.length > 0) {
        setLawyer(data.lawyers[0]);
      }
    };

    fetchLawyerDetails();
  }, [slugName]);

  if (!lawyer) {
    return <LoadingSpinner />;
  }

  const gradeClass = lawyer.lawGrade
    ? `lawyer-card-grade-${lawyer.lawGrade.toLowerCase()}`
    : "";

    return (
      <>
        <Helmet>
          <title>{`${lawyer.firstName} ${lawyer.lastName} - upLawyer.gr - Βρές δικηγόρο και άλλες νομικές υπηρεσίες στην Ελλάδα.`}</title>
          <meta
            name="description"
            content={`${lawyer.firstName} ${lawyer.lastName} - Δικηγόρος με ειδίκευση σε ${lawyer.lawSpecialty
              .map((s) => s.specialtyName)
              .join(", ")}.`}
          />
          <meta property="og:title" content={`${lawyer.firstName} ${lawyer.lastName} - upLawyer.gr`} />
          <meta property="og:description" content={`${lawyer.firstName} ${lawyer.lastName} - Δικηγόρος με ειδίκευση σε ${lawyer.lawSpecialty.map((s) => s.specialtyName).join(", ")}.`} />
          <meta property="og:image" content={lawyer.lawProfileImage.url} />
          <meta property="og:url" content={`https://uplawyer.gr/dikigoros/${slugName}`} />
        </Helmet>
  
        <Container className="my-4">
          <Row>
            <Col md={4}>
              <LawyerCard lawyer={lawyer} />
            </Col>
  
            <Col md={7} className="ms-md-5">
              <LawyerBiography
                lawSchoolUnder={lawyer.lawSchoolUnder}
                lawShortBio={lawyer.lawShortBio}
              />
              <LawyerEducation
                lawSchoolUnder={lawyer.lawSchoolUnder}
                lawSchoolPost={lawyer.lawSchoolPost}
              />
              <LawyerLanguages
                lawLanguage={lawyer.lawLanguage}
                languageToCountryCode={languageToCountryCode}
              />
            </Col>
          </Row>
  
          <Row>
            <Col md={12}>
              <LawyerBlogPosts lawBlogPost={lawyer.lawBlogPost} gradeClass={gradeClass} />
            </Col>
          </Row>
        </Container>
      </>
    );
}

export default LawyerDetailPage;
