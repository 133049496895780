import React, { useState } from "react";

/* Import UI components from Bootstrap UI */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

/* Import icons from from react-icons library  */
import { FaPaperPlane } from "react-icons/fa";

import OpenAILogo from '../images/openAI-logo.png';

const OpenAIPage = () => {
  const [response, setResponse] = useState("");
  const [inputText, setInputText] = useState("");

  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const getLawyerSpecialtyFromOpenAI = async (text) => {
    try {
      const result = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({
            model: "gpt-4o-mini",
            messages: [
              {
                role: "system",
                content: `
                  You are a legal assistant that categorizes legal cases into different specialties of law based on the case description. 
                  Here are the specialties and associated cases:
                  
                  - Αστικό Δίκαιο: Οικογενειακές υποθέσεις (διαζύγιο, επιμέλεια τέκνων, διατροφή, συμμετοχή στα αποκτήματα, προσβολή πατρότητας, υιοθεσία, δικαστική συμπαράσταση), αστική αποζημίωση (τροχαία ατυχήματα, ευθύνη από σύμβαση, αδικοπραξία), Ακίνητα (μίσθωση, πώληση, άδειες, υποθήκη, κυριότητα), Συμβάσεις, ιδιωτικές συμφωνίες, Κληρονομιά, Διαθήκη, Χρέη (Νόμος Κατσέλη κ.λ.π), προστασία καταναλωτή, αποζημίωση για βλάβη υγείας ή σώματος, δυσφημιστικές διαδόσεις. Επίσης, στο Αστικό Δίκαιο συμπεριλαμβάνονται οι αποζημιώσεις για τα ποινικά αδικήματα.
          
                  - Εμπορικό Δίκαιο: Εταιρικές Συμβάσεις, Νομικός έλεγχος εταιρειών, Σήματα, Ευρεσιτεχνίες, Πνευματική ιδιοκτησία, Εξαγορές και συγχωνεύσεις, Αναδιάρθρωση και διάσπαση, Λύση και εκκαθάριση, Πτώχευση-Συνδιαλλαγή-Εξυγίανση-Ειδική Εκκαθάριση επιχειρήσεων, Αξιόγραφα (επιταγές, συναλλαγματικές), Ασφαλιστικό δίκαιο (ιδιωτική ασφάλιση), Ναυτικό Δίκαιο, Αεροπορικό Δίκαιο.
          
                  - Εργατικό Δίκαιο: Ερμηνεία, καταγγελία σύμβασης εργασίας και καταβολή αποζημιώσεων, Αξιώσεις δεδουλευμένων, επιδομάτων, αδειών και μισθών υπερημερίας, Εργατικά ατυχήματα, Απονομή Συντάξεων, Αναθεώρηση όρων εργασίας, Συμβάσεις παροχής ανεξαρτήτων υπηρεσιών, συμβάσεις έργου και συμβάσεις εντολής, Αποζημίωση διοικητικών στελεχών, Ομαδικές απολύσεις, Ατομικό και Συλλογικό Εργατικό δίκαιο.
          
                  - Δίκαιο Ακινήτων: Πωλήσεις, Μισθώσεις, Απαλλοτριώσεις, Προσύμφωνα – Συμβόλαια Αγοραπωλησίας Ακινήτων, Έλεγχοι Τίτλων Ιδιοκτησίας ακινήτων, Εθνικό Κτηματολόγιο.
          
                  - Ποινικό Δίκαιο: Ποινικά αδικήματα (κλοπή, ληστεία, φόνος, δολοφονία, απάτη, σεξουαλική επίθεση, βία, σωματική βλάβη, ναρκωτικά, όπλα, οπλοφορία, ανθρωποκτονία, βιασμός, υπεξαίρεση, ηχορρύπανση, ζωοκλοπή, παρενόχληση), Δικαστική εκπροσώπηση σε ποινικά δικαστήρια, Εισαγγελέας, Ποινική υπεράσπιση, Κατηγορίες και απολογίες, Εγγυήσεις, Αποφυλακίσεις, Κατάχρηση εξουσίας, Παράνομες δραστηριότητες, Εγκλήματα κυβερνοχώρου, Εμπρησμός, Βία και κακοποίηση, σωματεμπορία, Εμπορία ανθρώπων, Διεθνές ποινικό δίκαιο, Έκδοση και ανάκριση, Αδικήματα ανηλίκων, αυτόφωρο, βία κατά υπαλλήλων, αστυνομικών, εξύβριση, μυνητήρια αναφορά, μήνυση, Πλημμέλημα, κακούργημα.
          
                  - Δίκαιο Αναγκαστικής Εκτέλεσης: Διαταγή πληρωμής, αναστολές, ανακοπές, Επιβολή κατάσχεσης, Είσπραξη απαιτήσεων – Ρύθμιση οφειλών.
          
                  - Διοικητικό Δίκαιο: Ιεραρχικές προσφυγές έναντι φορέων δημόσιας διοίκησης, Αγωγές, προσφυγές, ανακοπές, Διεκδίκηση μισθολογικών απαιτήσεων, βαθμολογικής εξέλιξης κ.λ.π., Αιτήσεις ακυρώσεως, Τροποποίηση ρυμοτομικού σχεδίου, Άρση απαλλοτρίωσης.
          
                  Based on the description, respond only with the exact name of the specialty, such as "Ποινικό Δίκαιο" or "Εμπορικό Δίκαιο". Do not add any extra text or explanations. If the description does not match a specific specialty, respond with "General". If the description is not related to any of these cases, respond with "Not a legal case".
                `,
              },
              { role: "user", content: `Case description: "${text}"` },
            ],
            max_tokens: 50,
            temperature: 0.0,
          }),
          
      });
  
      if (!result.ok) {
        throw new Error(`Error: ${result.status} ${result.statusText}`);
      }
  
      const data = await result.json();
      const content = data.choices[0].message.content.trim();
  
      return content;
    } catch (error) {
      console.error(
        "Error determining legal specialty with OpenAI API:",
        error
      );
      return "Error fetching response. Please try again.";
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResponse('');
    
    if (countWords(inputText) > 100) {
      setResponse('Το κείμενό σας ξεπερνά το όριο των 100 λέξεων. Παρακαλώ περιγράψτε την υπόθεση σας σε λιγότερες λέξεις.');
      return;
    }
  
    const specialtyResponse = await getLawyerSpecialtyFromOpenAI(inputText);
  
    if (specialtyResponse === 'Not a legal case') {
      setResponse('Μπορώ να απαντήσω μόνο σε ερωτήσεις σχετικές με νομικά ζητήματα.');
    } else if (specialtyResponse === 'General') {
      setResponse('Η υπόθεση σου δεν παρουσιάζει κάποια εξειδίκευση. Μπορείς να απευθυνθείς σε έναν δικηγόρο για να σε καθοδηγήσει.');
    } else if (specialtyResponse === 'Error fetching response. Please try again.') {
      setResponse(specialtyResponse);
    } else {
      const formattedSpecialty = specialtyResponse.split(' ')[0];
      setResponse(`Η υπόθεση σου μπορεί να χειριστεί από δικηγόρο με ειδίκευση στο "${formattedSpecialty} Δίκαιο".`);
    }
  };

  return (
    <Container className="d-flex flex-column align-items-center">
      <Row className="w-100">
        <Col xs={12} md={8} lg={6} className="mx-auto pt-5">
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mb-5 mt-5">
            <h1 className="text-center mb-2 mb-md-0">AI Ψηφιακός Βοηθός</h1>
            <small className="d-flex align-items-center ms-4 mt-md-0 mt-2">
              powered by 
              <img
                src={OpenAILogo}
                alt="OpenAI"
                style={{ height: '20px', marginLeft: '5px' }}
              />
            </small>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="inputAI" className="mb-3 position-relative">
              <Form.Control
                as="textarea"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Περιέγραψε την υπόθεσή σου (μέχρι 1000 λέξεις)..."
                rows={5}
                style={{ resize: "none", paddingRight: "2.5rem", paddingBottom: "2.5rem" }}
              />
              <Button
                type="submit"
                variant="outline-dark"
                className="position-absolute"
                style={{ right: "15px", bottom: "15px" }}
              >
                <FaPaperPlane />
              </Button>
            </Form.Group>
          </Form>
          {response && (
            <div className="mt-4 p-3 border bg-black">
              <p className="text-center text-response">{response}</p>
              <p className="text-center text-sm">Αυτή η πρόταση δημιουργήθηκε με βάση το εργαλείο τεχνητής νοημοσύνης της OpenAI και δεν υποκαθιστά την καθοδήγηση από έναν δικηγόρο.</p>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );

};

export default OpenAIPage;
