/**
 * useTypewriter is a custom React hook that creates a typewriter effect by progressively displaying
 * a given string of text one character (or a set number of characters) at a time. 
 * 
 * This hook is useful for creating dynamic text animations that mimic the look of text being typed 
 * on a typewriter, enhancing the visual appeal of user interfaces.
 * 
 * @param {string} text - The full string of text to be displayed character by character.
 * @param {number} speed - The delay in milliseconds between each character (or set of characters) being added. Default is 20ms.
 * @param {number} step - The number of characters to add at each interval. A higher step value makes the typing appear faster. Default is 5.
 * 
 * @returns {string} displayText - The portion of the text that has been "typed" so far, which updates over time until the entire string is displayed.
 * 
 * @example
 * const typedText = useTypewriter("Hello, world!", 50, 1);
 * 
 * return <h1>{typedText}</h1>; 
 * // The text "Hello, world!" will appear one character at a time with a delay of 50ms between each character.
 */

import { useState, useEffect } from "react";

const useTypewriter = (text, speed = 20, step = 5) => {
  const [index, setIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + text.slice(index, index + step));
        setIndex((prevIndex) => prevIndex + step);
      }, speed);

      return () => clearTimeout(timeout);
    }
  }, [index, text, speed, step]);

  return displayText;
};

export default useTypewriter;
