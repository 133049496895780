import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDsigoGcabhyMLNW-qL4cSdEpI2g93vRhc",
    authDomain: "uplawyer-cc44e.firebaseapp.com",
    projectId: "uplawyer-cc44e",
    storageBucket: "uplawyer-cc44e.appspot.com",
    messagingSenderId: "962156597033",
    appId: "1:962156597033:web:ca2decd25adc47ecd23138",
    measurementId: "G-541JBVP7JY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
