import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieUI = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setIsVisible(false);
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'declined', { expires: 365 });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-consent">
      <p>Ο ιστότοπος χρησιμοποιεί cookies για την βελτίωση της εμπειρίας χρήσης του.</p>
      <button onClick={handleAccept}>Αποδοχή</button>
      <button onClick={handleDecline}>Απόρριψη</button>
    </div>
  );
};

export default CookieUI;
