import React, { useEffect, useState } from 'react';

/* Import fetchGraphQLData to facilitate the apiCalls to the Hygraph API */
import fetchGraphQLData from "../apiCalls/fetchGraphQLData";

/* Import UI components from react-bootstrap library */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

/* Import icons from from react-icons library  */
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function LawAssociationsAll() {
  const [associations, setAssociations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssociationsAndLawyers = async () => {
      const associationsQuery = `
      {
        lawAssociations(first: 100) {
          id
          associationName
          associationSlug
        }
      }
      `;

      const lawyersQuery = `
      {
        lawyers(first: 100) {
          id
          lawAssociation {
            id
          }
        }
      }
      `;

      try {
        const associationsData = await fetchGraphQLData(associationsQuery);
        const lawyersData = await fetchGraphQLData(lawyersQuery);

        if (associationsData && associationsData.lawAssociations && lawyersData && lawyersData.lawyers) {
          const associationMap = {};

          associationsData.lawAssociations.forEach((association) => {
            associationMap[association.id] = {
              ...association,
              lawyerCount: 0,
            };
          });

          lawyersData.lawyers.forEach((lawyer) => {
            const { lawAssociation } = lawyer;
            if (lawAssociation && associationMap[lawAssociation.id]) {
              associationMap[lawAssociation.id].lawyerCount += 1;
            }
          });

          const associationsWithCounts = Object.values(associationMap);
          setAssociations(associationsWithCounts);
        } else {
          console.error('Error: Data structure is not as expected:', associationsData, lawyersData);
        }
      } catch (error) {
        console.error('Error making API request:', error);
      }
    };

    fetchAssociationsAndLawyers();
  }, []);

  const handleRedirect = (slug) => {
    navigate(`/dikigorikoi-syllogoi/${slug}`);
  };

  return (
    <>
    <Container className="my-3">
      <Row className="mx-1">
        <h1 className="my-2">Δικηγορικοί Σύλλογοι</h1>
        <h5 className="mb-5">Η λίστα των διαθέσιμων δικηγορικών συλλόγων του upLawyer.</h5>
      </Row>
      <Row>
        {associations.map((association) => (
          <Col key={association.id} sm={12} md={6} lg={4} className="mb-4">
            <Card className="card-association">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="fw-medium">{association.associationName}</span>
                  <FaArrowRight
                    className="text-black cursor-pointer association-arrow mx-3"
                    onClick={() => handleRedirect(association.associationSlug)}
                  />
                </div>
                <div className="text-md fw-light mt-2">
                  <span>{association.lawyerCount} διαθέσιμοι δικηγόροι</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </>
  );
}

export default LawAssociationsAll;
