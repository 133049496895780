import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../apiCalls/firebaseConfig';
import { signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';

function ProfilePage() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      setUserInfo({
        email: auth.currentUser.email,
        displayName: auth.currentUser.displayName,
        photoURL: auth.currentUser.photoURL,
      });
    }
  }, []);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out');
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match.');
      return;
    }

    setLoading(true);
    
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            setSuccessMessage('Password updated successfully.');
            setLoading(false);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
          })
          .catch((error) => {
            console.error('Error updating password:', error);
            setError('Failed to update password. Please try again.');
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error re-authenticating user:', error);
        setError('Current password is incorrect.');
        setLoading(false);
      });
  };

  return (
    <Container className="my-4">
      <Row>
        <Col md={6}>
          <h2 className="mb-4">Profile</h2>
          {userInfo && (
            <>
              <p><strong>Email:</strong> {userInfo.email}</p>
              {userInfo.displayName && <p><strong>Name:</strong> {userInfo.displayName}</p>}
              {userInfo.photoURL && (
                <div className="mb-3">
                  <img src={userInfo.photoURL} alt="Profile" className="img-fluid rounded-circle" width="150" />
                </div>
              )}
              <Button variant="danger" onClick={handleSignOut}>
                Sign Out
              </Button>
            </>
          )}
        </Col>
        <Col md={6}>
          <h2 className="mb-4">Change Password</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          
          <Form onSubmit={handlePasswordChange}>
            <Form.Group controlId="currentPassword" className="mb-3">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </Form.Group>
            
            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            
            <Form.Group controlId="confirmNewPassword" className="mb-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            
            <Button variant="primary" type="submit" disabled={loading}>
              Change Password
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfilePage;
