import React, { useEffect, useState } from 'react';
import fetchGraphQLData from '../apiCalls/fetchGraphQLData';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LawyerCard from '../components/LawyerCard';
import { Helmet } from 'react-helmet';

function LawyersPage() {
  const [lawyers, setLawyers] = useState([]);

  useEffect(() => {
    const fetchLawyers = async () => {
      const query = `
      {
        lawyers {
          id
          firstName
          lastName
          slugName
          lawSpecialty {
            specialtyName
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
        }
      }
      `;
      const data = await fetchGraphQLData(query);
      if (data) {
        setLawyers(data.lawyers);
      }
    };

    fetchLawyers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Δικηγόροι - upLawyer.gr - Βρές δικηγόρο και άλλες νομικές υπηρεσίες στην Ελλάδα.</title>
        <meta
          name="description"
          content="Η λίστα των διαθέσιμων δικηγόρων του upLawyer. Βρείτε εξειδικευμένους δικηγόρους για τις νομικές σας ανάγκες."
        />
      </Helmet>

      <Container className="my-3">
        <Row className="mx-1">
          <h1 className="my-2">Δικηγόροι</h1>
          <h5 className="mb-5">Η λίστα των διαθέσιμων δικηγόρων του upLawyer.</h5>
        </Row>
        <Row>
          {lawyers.map((lawyer) => (
            <Col key={lawyer.id} sm={12} md={6} lg={4} className="mb-4">
              <LawyerCard lawyer={lawyer} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default LawyersPage;
