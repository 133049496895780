import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

/* Import UI components from from react-bootstrap library  */
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

/* Import icons from from react-icons library  */
import { FaRobot } from 'react-icons/fa';

function NavBarUI() {
  const [navbarBlur, setNavbarBlur] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setNavbarBlur(true);
      } else {
        setNavbarBlur(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <>
      <Navbar 
        expand="lg" 
        className={`fixed-top ${navbarBlur || isNavbarOpen ? 'navbar-blur' : 'navbar-transparent'}`} 
        expanded={isNavbarOpen}
      >
        <Container className="d-flex justify-content-between align-items-center">
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} className="order-lg-last order-1" />
          <Navbar.Brand as={Link} to="/" className="order-lg-first order-2 ms-auto">
            <strong>upLawyer<sub><small>beta</small></sub></strong>
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav" className="order-3">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/dikigoroi">Δικηγόροι</Nav.Link>
              <Nav.Link as={Link} to="/dikigorikoi-syllogoi">Δικηγορικοί Σύλλογοι</Nav.Link>
              <Nav.Link as={Link} to="/blog">Αρθρογραφία</Nav.Link>
            </Nav>
            <Nav className="ms-auto d-flex align-items-center">
              <a href="/ai-assistant" className="btn btn-outline-dark nav-btn">
                <FaRobot className="mb-1 me-2" /> AI βοηθός
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={`main-content ${isNavbarOpen ? 'blur-background' : ''}`}>
        {/* Your main page content goes here */}
      </div>
    </>
  );
}

export default NavBarUI;
