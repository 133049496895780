import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import iconImage from '../images/contact-us.png';

const Contact = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      emailjs.send(
        'service_eowssfo',
        'template_tmgliwr',
        formData,
        'tYhu1RNA5gZE5MZmi'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('FAILED...', error);
        alert('Failed to send email. Please try again later.');
      });
    };
  
    return (
        <Container>
          <Row>
            <Col lg={5} className="d-none d-lg-block">
            <img
                src={iconImage}
                alt="Contact Icon"
                className="img-fluid"
                style={{ width: '120%', height: '120%', objectFit: 'cover' }}
            />
            </Col>
    
            {/* Right column with form */}
            <Col lg={7} className="d-flex align-items-center justify-content-center">
              <div className="w-100 mx-5">
                <h2 className="h2-page-title text-center mb-4">Χρειάζεσαι βοήθεια ;</h2>
                <Form onSubmit={handleSubmit}>
                  <FloatingLabel controlId="formName" label="Όνομα" className="mb-4">
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Όνομα"
                      required
                    />
                  </FloatingLabel>
    
                  <FloatingLabel controlId="formEmail" label="Email" className="mb-4">
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      required
                    />
                  </FloatingLabel>
    
                  <FloatingLabel controlId="formSubject" label="Θέμα" className="mb-4">
                    <Form.Control
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Θέμα"
                    />
                  </FloatingLabel>
    
                  <FloatingLabel controlId="formMessage" label="Μήνυμα" className="mb-4">
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Μήνυμα"
                      style={{ height: '100px' }}
                      required
                    />
                  </FloatingLabel>
    
                  <Button variant="outline-dark" type="submit">
                    Αποστολή
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      );
    };    

  
  export default Contact;
