import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Import fetchGraphQLData to facilitate the apiCalls to the Hygraph API */
import fetchGraphQLData from "../apiCalls/fetchGraphQLData";

/* Import UI components from react-bootstrap library */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/* Import UI components from Material UI */
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

/* Import custom UI components of the upLawyer application */
import SelectUI from "../components/ui-select";
import LawyerCard from "../components/LawyerCard";

/* Import custom hooks to the upLawyer application */
import useTypewriter from "../hooks/useTypewriter";


function HomePage() {
  const [specialties, setSpecialties] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [lawyers, setLawyers] = useState([]);
  const [filteredLawyers, setFilteredLawyers] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [selectedAssociation, setSelectedAssociation] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();

  const typewriterText = useTypewriter("Αναζήτηση δικηγόρου, ειδικότητας και νομικής υπηρεσίας.", 100);

  useEffect(() => {
    const fetchData = async () => {
      const specialtiesQuery = `
      {
        lawSpecialties(first: 100) {
          id
          specialtyName
          specialtySlug
        }
      }
      `;

      const associationsQuery = `
      {
        lawAssociations(first: 100) {
          id
          associationName
          associationSlug
        }
      }
      `;

      const lawyersQuery = `
      query GetLawyers {
        lawyers {
          id
          firstName
          lastName
          slugName
          lawSpecialty {
            specialtyName
            specialtySlug
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
        }
      }
    `;

      try {
        const specialtiesData = await fetchGraphQLData(specialtiesQuery);
        const associationsData = await fetchGraphQLData(associationsQuery);
        const lawyersData = await fetchGraphQLData(lawyersQuery);

        if (specialtiesData) {
          setSpecialties(
            specialtiesData.lawSpecialties.map((specialty) => ({
              id: specialty.id,
              name: specialty.specialtyName,
              slug: specialty.specialtySlug,
            }))
          );
        }

        if (associationsData) {
          setAssociations(
            associationsData.lawAssociations.map((association) => ({
              id: association.id,
              name: association.associationName,
              slug: association.associationSlug,
            }))
          );
        }

        if (lawyersData) {
          setLawyers(lawyersData.lawyers);
          setFilteredLawyers(lawyersData.lawyers);
        }
      } catch (error) {
        console.error("Error making API request:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterLawyers = () => {
      let filtered = [...lawyers];

      if (selectedSpecialty) {
        filtered = filtered.filter((lawyer) =>
          lawyer.lawSpecialty.some(
            (specialty) => specialty.specialtySlug === selectedSpecialty
          )
        );
      }

      if (selectedAssociation) {
        filtered = filtered.filter(
          (lawyer) =>
            lawyer.lawAssociation &&
            lawyer.lawAssociation.associationSlug === selectedAssociation
        );
      }

      setFilteredLawyers(filtered);
    };

    filterLawyers();
  }, [selectedSpecialty, selectedAssociation, lawyers]);

  useEffect(() => {
    const filterSearchResults = () => {
      if (inputValue.length < 2) {
        setSearchResults([]);
        return;
      }

      const specialtyResults = specialties
        .filter((specialty) =>
          specialty.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((specialty) => ({ ...specialty, type: "specialty" }));

      const lawyerResults = lawyers
        .filter(
          (lawyer) =>
            lawyer.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
            lawyer.lastName.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((lawyer) => ({ ...lawyer, type: "lawyer" }));

      setSearchResults([...specialtyResults, ...lawyerResults]);
    };

    filterSearchResults();
  }, [inputValue, specialties, lawyers]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Container
        fluid
        className={`d-flex flex-column align-items-center ${
          scrolled ? "scrolled" : "vh-100"
        }`}
        style={{ paddingTop: "50px" }}
      >
        <div
          className={`d-flex flex-column align-items-center transition-container ${
            scrolled ? "scrolled-container" : ""
          }`}
        >
          {/* Heading and Input */}
          <h1 className={`text-center ${scrolled ? "scrolled-heading" : "pb-4"}`}>
            Αναζήτηση δικηγόρων
          </h1>

          <Col xs={12} md={6} className={`mb-3 ${scrolled ? "" : "extra-pad"}`}>
            <Autocomplete
              options={searchResults}
              groupBy={(option) =>
                option.type === "lawyer" ? "Lawyers" : "Specialties"
              }
              getOptionLabel={(option) =>
                option.type === "lawyer"
                  ? `${option.firstName} ${option.lastName}`
                  : option.name
              }
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onChange={(event, newValue) => {
                if (newValue) {
                  if (newValue.type === "lawyer") {
                    navigate(`/dikigoros/${newValue.slugName}`);
                  } else if (newValue.type === "specialty") {
                    setSelectedSpecialty(newValue.slug);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={typewriterText}
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiAutocomplete-endAdornment": {
                      display: "none",
                    },
                  }}
                />
              )}
              open={inputValue.length >= 2}
              disableOpenOnFocus={true}
              clearOnBlur={false}
              className={`autocomplete ${
                scrolled ? "scrolled-autocomplete" : ""
              }`}
            />
          </Col>

          <p className={`text-center intro-p mt-5 ${scrolled ? "scrolled-text" : ""}`}>
            Το upLawyer.gr προσφέρει την κορυφαία εμπειρία αναζήτησης δικηγόρου και νομικών υπηρεσιών στην Ελλάδα.
          </p>
        </div>
      </Container>

      <Container className="my-5">
        <Row className="mb-4">
          <Col xs={12} md={6} className="mb-4">
            <SelectUI
              label="Είδος Δικαίου"
              options={specialties}
              selectedValue={selectedSpecialty}
              onChange={(value) => setSelectedSpecialty(value || "")}
              placeholder="Όλες οι Ειδικότητες"
            />
          </Col>
          <Col xs={12} md={6} className="mb-3">
            <SelectUI
              label="Δικηγορικός Σύλλογος"
              options={associations}
              selectedValue={selectedAssociation}
              onChange={(value) => setSelectedAssociation(value || "")}
              placeholder="Όλοι οι Δικηγορικοί Σύλλογοι"
            />
          </Col>
        </Row>

        <Row>
          {filteredLawyers.map((lawyer) => (
            <Col key={lawyer.id} sm={4} className="mb-4">
              <LawyerCard lawyer={lawyer} />
            </Col>
          ))}
        </Row>
        
      </Container>
    </div>
  );
}

export default HomePage;
