import axios from 'axios';

const URL = 'https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/cm076afrs03tn07w4hrdwp8vl/master';
const AUTH_TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MjQ1MTkzMjYsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NtMDc2YWZyczAzdG4wN3c0aHJkd3A4dmwvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtdXMtZWFzdC0xLXNoYXJlZC11c2VhMS0wMi5oeWdyYXBoLmNvbS8iLCJzdWIiOiIzMThmNjIzMC1mOWU5LTRlMDEtYmE1Ny03NWNjNjMwMTFiOGYiLCJqdGkiOiJjbTA4ZWE0aTcwcXpuMDdrOWQ2M2E5cm1iIn0.yWBWyAIBEOZNEduBlI-6CgrUHnzAyij-3Bv7G_6gbPlLERdDW7usHO1AaZK8prIJ2PCTHQhAB9PQXGSmi5FRfTJpGCxXOaAwsfqrkrnBeG8i4bSi8K-BYNbDU2yAMXT-ZsZagNT_2jkyLXFEc3cgNqBtK50rWIAmcbSPxmQy8It-5SWK3q_SewYEVLcFZ7_3lN0G6k_P_u3mQvFcOsluTDGG24-TEeu6O4M0njK2gpLpBbyl8ZNZwYjEaLT7dMjXMzznc3Fo1CLW9WSZUFtMj9C2p4BLlIBioj8jZAxE-443GTSWDnCoUnh2J7ZIfHgGC8gpX6m5cmK2MMvfLXjSzFvZCqHFlbnF5CGj5-3WWmpVgZhzoO4h28cjx668mjKlRE1HwjVF8LYRLOjh1ZxjWxuMlsQd5rpLB3DrEuXTlvG_APZGOZdzZ2wwdNSe3AFMrhS-DtfT8H4GAFsb6UTamSeeiaJ3syNw0IwovP6mNsPLcIpcuzgbh-RU2OYQH83IsYL5e8N_KbX1ZLtIKHmCf40KnJGZafO9R-pUzqpoe1sAEeVt-7NATRC3Chc8ZYzQ1IUoUIF2__Wt-EDLapsaf3JwUutJFSpWI03VSy5jGBsiJvelmt-at8_cGm5_9eF6EllYwlIHJF0s7cSaQCr5ioAJeR9bcYkitGjRL62UXOA';

const fetchGraphQLData = async (query) => {
  try {
    const headers = {
      Authorization: `Bearer ${AUTH_TOKEN}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(URL, { query }, { headers });

    if (response.status === 200) {
      if (process.env.NODE_ENV === 'development') {
        console.log('GraphQL Response:', response.data);
      }
      return response.data.data;
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Request failed with status code ${response.status}: ${response.statusText}`);
      }
      return null;
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Error making API request:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
    }
    return null;
  }
};

export default fetchGraphQLData;
